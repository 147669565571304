export default [
  {
    path: "/no-property-access",
    name: "no-property-access",
    component: () =>
      import(
        /* webpackChunkName: "errors" */ "../views/errors/NoPropertyAccess.vue"
      ),
  },
  {
    path: "/*",
    name: "not-found",
    component: () =>
      import(
        /* webpackChunkName: "errors" */ "../views/errors/NotFound404.vue"
      ),
  },
];
