import { NavigationGuardNext, Route } from "vue-router";
import api from "@/api";
import { PropertyStatusCategory } from "@/enums/StatusCategory";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { resolveRoutePath } from "@/router";
import { ApiErrorCode } from "@/enums/ErrorCode";
import ExperimentService from "@/services/ExperimentService";

export default async function (to: Route, _: Route, next: NavigationGuardNext) {
  if (
    to.name === "accounting" &&
    !ExperimentService.isActiveExperiment("MYRENTALS_ACCOUNTING_BETA_ROLLOUT")
  ) {
    // eslint-disable-next-line xss/no-location-href-assign
    location.href = "/not-found";
  }

  const propertyUuid = to.params.propertyUuid;
  const deal = to.params.dealId;

  try {
    let newPath = "";

    await handleUserIsNotPropertyOwnerOrAppointmentManager(propertyUuid);

    const propertyStatusCategory = (
      await api.propertyStatusCategory().get(propertyUuid)
    ).propertyStatusCategory;

    if (propertyStatusCategory === PropertyStatusCategory.RENTALS)
      return next();

    if (propertyStatusCategory === PropertyStatusCategory.OLD_MYH)
      newPath = resolveRoutePath(
        deal
          ? MYHExternalRoutes.dashboardNewProperty(propertyUuid, deal)
          : MYHExternalRoutes.dashboardProperty(propertyUuid)
      );
    else if (propertyStatusCategory === PropertyStatusCategory.RE_PUBLISHED)
      newPath = resolveRoutePath(
        MYHExternalRoutes.myRealEstateProperty(propertyUuid)
      );

    if (newPath)
      // eslint-disable-next-line xss/no-location-href-assign
      location.href = newPath;
    else {
      next();
    }
  } catch (error) {
    // eslint-disable-next-line xss/no-location-href-assign
    location.href = "/not-found";
  }
}

async function handleUserIsNotPropertyOwnerOrAppointmentManager(
  propertyUuid: string
) {
  try {
    await api.property().retrieveInfo(propertyUuid);
  } catch (error) {
    if (error?.code === ApiErrorCode.USER_IS_NOT_OWNER_OR_APPOINTMENT_MANAGER) {
      // eslint-disable-next-line xss/no-location-href-assign
      location.href = "/no-property-access";
    }
  }
}
