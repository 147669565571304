/* eslint-disable no-unused-vars */

//For Back-Defined ErrorCodes
export enum ApiErrorCode {
  UNKNOWN = 0,
  AUTH_INVALID_IMPERSONATION = 1018,
  AUTH_IMPERSONATION_UNAUTHORIZED = 1023,
  USER_IS_NOT_OWNER_OR_APPOINTMENT_MANAGER = 5,
}

//For Front-Defined ErrorCodes
export enum ErrorCode {
  NO_TOKEN = 1,
}
