import {
  IExperimentsByPercentage,
  IExperimentsByUserId,
} from "@/models/Experiments";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Environment } from "@/enums/Config";

const getAccountingBetaExperimentByUserId = ():
  | IExperimentsByUserId
  | undefined => {
  if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) {
    return {
      MYRENTALS_ACCOUNTING_BETA_ROLLOUT: {
        id: "MYRENTALS_ACCOUNTING_BETA_ROLLOUT",
        name: "MYRENTALS_ACCOUNTING_BETA_ROLLOUT",
        users: [
          718, 15897, 109224, 838090, 685949, 1374178, 514339, 1391703, 664526,
          1732901, 1834493, 1911898, 543488, 1869380, 1545553, 74891, 864740,
          1803608, 66934, 1767229, 1040573, 587294, 1744696, 1770172, 747448,
          1507702, 1717883, 1550637, 1001727, 1725481, 1185575, 1710723,
          1248222, 692902, 322975, 779431, 922058, 732695, 431860, 967808,
          491428, 446618, 934788, 1231654, 830027, 1685558, 1665216, 1216466,
          50296, 187565, 194107, 440189, 542774, 566209, 577617, 579786, 614888,
          652502, 656179, 676394, 683988, 686150, 689786, 715259, 718202,
          737540, 752091, 774472, 785659, 796762, 801000, 826670, 829364,
          833479, 842341, 862330, 872423, 874470, 892457, 894668, 931143,
          939042, 956065, 984438, 993727, 994626, 1007706, 1023547, 1029319,
          1033136, 1045099, 1048304, 1048565, 1063362, 1106546, 1106843,
          1113840, 1115438, 1120295, 1152304, 1161467, 1163420, 1166241,
          1172330, 1178812, 1185113, 1190707, 1208765, 1218958, 1225676,
          1227557, 1234986, 1246452, 1247480, 1249202, 1259918, 1267133,
          1290317, 1294155, 1300989, 1301749, 1303936, 1315755, 1315934,
          1327189, 1347869, 1350874, 1351268, 1352787, 1353130, 1366601,
          1369814, 1379204, 1399263, 1406575, 1408915, 1418182, 1429817,
          1434460, 1435757, 1439258, 1462630, 1480566, 1486719, 1500315,
          1501053, 1504191, 1510479, 1510523, 1515253, 1518294, 1525864,
          1561972, 1562404, 1597934, 1696494, 1701277, 1757303, 1757520,
          1778538, 1785142, 1788818, 1789637, 1803310, 1810861, 1813163,
          1813606, 1817915, 1833437, 1834284, 1843382, 1901605, 1906576,
          1919280, 1933336, 1971424, 2000207, 342104, 406991, 671119, 671778,
          771668, 804523, 839739, 846319, 851487, 879724, 893044, 931341,
          958442, 972913, 974286, 976961, 991955, 993145, 1003615, 1005180,
          1017878, 1030545, 1030711, 1039489, 1040003, 1049437, 1051771,
          1074214, 1093863, 1100301, 1101777, 1103076, 1115499, 1131883,
          1144618, 1165890, 1168069, 1170421, 1172478, 1174860, 1175675,
          1177341, 1180093, 1184845, 1187543, 1189199, 1201406, 1204269,
          1204591, 1208891, 1213649, 1227258, 1242743, 1245285, 1251407,
          1265567, 1282386, 1282859, 1283581, 1291934, 1307444, 1309288,
          1338925, 1340920, 1351763, 1355500, 1358595, 1363144, 1363975,
          1370895, 1396742, 1413699, 1418317, 1423836, 1474340, 1475349,
          1477240, 1484353, 1496826, 1514140, 1515181, 1516942, 1525790,
          1530944, 1537402, 1559836, 1561176, 1566839, 1569150, 1575283,
          1580120, 1584572, 1584846, 1590662, 1591174, 1603037, 1605231,
          1614767, 1615003, 1615189, 1617502, 1620150, 1629128, 1632421,
          1649814, 1654076, 1666824, 1668226, 1678084, 1707718, 1708111,
          1717886, 1743370, 1745626, 1746251, 1750475, 1759523, 1774721,
          1775371, 1776204, 1779125, 1782607, 1797114, 1800781, 1835725,
          1838343, 1840563, 1847536, 1851025, 1852609, 1858296, 1866660,
          1887724, 1902491, 1911353, 1914779, 1931587, 1936341, 1946240,
          1953659, 1954241, 1954705, 1959381, 1967263, 1971023, 2025687, 358216,
          407717, 430259, 532844, 544990, 655286, 761844, 868378, 874764,
          876679, 876851, 877327, 878572, 895837, 917414, 918329, 927564,
          928792, 941334, 943252, 944880, 990400, 995237, 997623, 1001876,
          1003443, 1010272, 1017039, 1019054, 1020000, 1031762, 1033566,
          1041883, 1060297, 1067797, 1069019, 1072597, 1084349, 1085483,
          1092266, 1094242, 1096388, 1097103, 1102414, 1115790, 1119521,
          1126659, 1136864, 1144537, 1149223, 1150233, 1170050, 1178351,
          1179142, 1193923, 1194919, 1195498, 1198030, 1204018, 1213190,
          1217071, 1218834, 1246851, 1252507, 1254789, 1267244, 1282150,
          1285553, 1285732, 1306313, 1319085, 1325006, 1356185, 1361321,
          1361578, 1368536, 1371792, 1377904, 1404520, 1424275, 1425948,
          1426222, 1437640, 1444579, 1448705, 1466820, 1471441, 1476760,
          1482142, 1507809, 1515794, 1518735, 1539444, 1556520, 1556817,
          1570170, 1581496, 1603815, 1610053, 1643688, 1670785, 1681360,
          1689642, 1693325, 1719731, 1726105, 1726246, 1733896, 1752999,
          1763628, 1766607, 1771195, 1774204, 1778919, 1783355, 1790251,
          1805542, 1815403, 1819909, 1831095, 1838531, 1840482, 1841662,
          1847383, 1848918, 1850207, 1873389, 1876604, 1883952, 1888649,
          1894986, 1938383, 1945266, 1945624, 1948812, 1955159, 1966752,
          1980826, 1983717, 1993604, 18619, 163110, 259142, 360612, 468548,
          477366, 480761, 489724, 553985, 562699, 583027, 603660, 611180,
          616796, 625891, 643404, 659742, 667720, 677344, 684651, 688813,
          693562, 698366, 704280, 707556, 720748, 725304, 735027, 739068,
          747686, 747830, 761698, 766094, 777995, 780176, 780603, 788704,
          790150, 790189, 791546, 798207, 802499, 814290, 821111, 823851,
          829075, 855034, 880218, 911277, 925538, 931370, 934739, 935310,
          948476, 953799, 959300, 966545, 975183, 987060, 1001299, 1004373,
          1008108, 1009526, 1013905, 1018763, 1025414, 1025808, 1026464,
          1026493, 1027947, 1028021, 1033973, 1042550, 1044103, 1047254,
          1049765, 1051005, 1051414, 1054287, 1058597, 1063947, 1069768,
          1078009, 1078438, 1093121, 1096890, 1098924, 1102285, 1111613,
          1113645, 1115640, 1122037, 1123678, 1125023, 1125278, 1128920,
          1130498, 1140758, 1143319, 1143645, 1145978, 1147511, 1148255,
          1153529, 1153575, 1156562, 61782, 114587, 134434, 184216, 214269,
          246290, 251653, 262471, 283882, 299178, 305739, 378910, 426039,
          475151, 579177, 580773, 597335, 600808, 612496, 632005, 634828,
          641500, 644964, 656641, 664325, 676192, 690635, 697211, 698648,
          701458, 704064, 717364, 718422, 720649, 722694, 732600, 742699,
          743792, 745178, 752099, 756195, 764939, 765696, 774201, 792888,
          828627, 829929, 829987, 831046, 833538, 845349, 893707, 893753,
          897677, 907032, 910418, 911247, 919299, 920975, 922146, 927982,
          943886, 946533, 946786, 964673, 965269, 974159, 974446, 975212,
          985983, 992536, 1001223, 1005321, 1013881, 1021543, 1024505, 1028738,
          1060132, 1067051, 1080359, 1081895, 1082028, 1089518, 1117340,
          1119035, 1154169, 1160636, 1164744, 1168462, 1168787, 1168968,
          1178608, 1190824, 1196164, 1196625, 1208807, 1213626, 1219357,
          1223679, 1256743, 1263638, 1265699, 1285879, 1289007, 1289222,
          1289489, 1302582, 73339, 76515, 82175, 110607, 121417, 155133, 189810,
          212413, 239640, 485675, 505847, 525097, 560152, 657517, 679155,
          713612, 719612, 719976, 739989, 762660, 808036, 818869, 829418,
          836529, 836706, 852458, 852692, 894541, 895890, 933569, 943149,
          944873, 948564, 1042808, 1095959, 1114191, 1145579, 1189378, 1196782,
          1215441, 1300868, 1311475, 1330073, 1379008, 1382736, 1396206,
          1396678, 1415710, 1420358, 1438409, 1452490, 1501656, 1558797,
          1564274, 1574241, 1577458, 1578187, 1593849, 1596565, 1599004,
          1621025, 1639380, 1646891, 1655269, 1655745, 1686119, 1720164,
          1757375, 1758290, 1808777, 1954001, 1954004, 1972898, 1981953,
          1982366, 1991190,
        ],
        variant: "B",
      },
    };
  }
};

const getAccountingBetaExperimentByPercentage = ():
  | IExperimentsByPercentage
  | undefined => {
  if (MyRentalsConfig.env().ENVIRONMENT === Environment.PROD) return;

  return {
    MYRENTALS_ACCOUNTING_BETA_ROLLOUT: {
      id: "MYRENTALS_ACCOUNTING_BETA_ROLLOUT",
      name: "MYRENTALS_ACCOUNTING_BETA_ROLLOUT",
      options: [0, 100],
      lastReset: [0, 0],
    },
  };
};

export const EXPERIMENTS_BY_PERCENTAGE: IExperimentsByPercentage = {
  ...getAccountingBetaExperimentByPercentage(),
};

export const EXPERIMENTS_BY_USER_ID: IExperimentsByUserId = {
  MYRENTALS_BETA_ROLLOUT: {
    id: "MYRENTALS_BETA_ROLLOUT",
    name: "MYRENTALS_BETA_ROLLOUT",
    users: [718, 15897, 838090, 109224, 685949],
    variant: "B",
  },
  ...getAccountingBetaExperimentByUserId(),
};
